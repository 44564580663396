 .desi img {
    width: 470px;
    height: 100%;
  }
  @media screen and (max-width: 768px) {    
    .desi img {
      max-width: 50% !important;
    }
  }
  @media screen and (max-width: 768px) {    
    .desi img {
      max-width: 80%;
      height: 100%;
      display: flex;
      justify-content: center;
      margin: 0 auto;
    }
  
  }
  @media screen and (min-width: 900px) and (max-width:1240px){
    .desi img {
      max-width: 100%;
      height: 100%;
    }
  }
  .desi img:focus {
    outline: none;
    border: none;
  }