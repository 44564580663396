.lineImage {
  margin-bottom: 3px;
}
.incomeTitleexpenses {
  color: #f5b820;
  font-family: "Montserrat";
  font-size: 16px !important;
  font-style: normal;
  font-weight: 500 !important;
  line-height: normal;
  text-transform: uppercase;
  margin-left: 10px;
}
.expensesmaintitlewrap {
  margin-top: 10px;
}
.title {
  color: #000;
  font-family: "Montserrat";
  font-size: 36px !important;
  font-style: normal;
  font-weight: 700 !important;
  line-height: 51px; 
}
@media only screen and (max-width : 600px){
  .title {
    font-size: 20px !important;
    line-height: 36px; 
  }  
}
.containerwrap {
  margin-top: 24px !important;
}
.textheading {
  color: #272521;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%; 
  letter-spacing: 0.4px;
  display: block;
}
.textdescription {
  color: rgba(30, 30, 30, 0.6);
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; 
}
.circlewithtext {
  width: 75.508px;
  height: 76px;
  flex-shrink: 0;
  border-radius: 50%;
  background-color: #fdd26b;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media screen and (max-width: 440px) {
  .textheading {
    text-align: center;
  }
  .circlewithlogo {
    margin: 0 auto !important;
  }
}
@media screen and (max-width: 768px) {
  .expensesmaintitlewrap {
    width: auto !important;
  }
}
