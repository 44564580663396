.featuresmainwrap{
  padding-top: 80px;
  
}
.title{
  font-size: 36px;
  line-height: 51px;
  font-weight: 700;
  text-align: center;
  color: #000;
  margin-bottom: 15px;
}
.subtitle{
  font-size: 20px;
  line-height: 30px;
  font-weight: 400;
  text-align: center;
  color:rgba(30, 30, 30, 0.6);
  margin-bottom: 70px;
 
}

.Featuresofdesihisabwrap{
  background-color: #F6F6F6;
  padding: 80px 0px;
}


.featurecolwrap{
  padding: 10px;
}
.featurecol{
  background-color: #fff;
  padding: 30px;
  text-align: center;
  height: 540px;
  
}
.featureboxtitle{
  font-size: 20px;
  line-height: 30px;
  font-weight: 700;
  text-align: center;
  color:#000;
  margin-top: 30px;
  margin-bottom: 10px;

}
.featureboxdesc{
  font-size: 15px;
  line-height: 26px;
  font-weight: 400;
  text-align: justify;
  color:rgba(30, 30, 30, 0.6);
  padding: 0px 12px;
  
}


@media only screen and (max-width: 900px) {
  .featurecol{
    height: auto;
    padding: 15px;
  }
  .subtitle{
    padding: 0px 15px;
  }
  
}
