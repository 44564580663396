.InviteFriendsSection_mainsection{
  background: #F6F6F6;
}
.InviteFriendsSection_mainwrap {
  padding: 26px;
  margin-top: 40px !important;
}
.logosection {
  display: flex;
  align-items: center;
  justify-content: center;
}
.InviteFriendsSection {
  display: flex;
  align-items: center;
  justify-content: center;
}
@media screen and (max-width: 768px){
  .InviteFriendsSection_mainwrap {
    height: auto !important;
    padding: 10px;
    gap: 30px;
  }
  .InviteFriendsSection{
    display: block !important;
  }
}
@media screen and (min-width: 769px) and (max-width:899px){
  .InviteFriendsSection_mainwrap{
    height: auto !important;
    gap: 30px !important;
  }
}
@media screen and (min-width: 900px) and (max-width:1240px){
  .InviteFriendsSection_mainwrap{
    height: auto !important;
  }
  .InviteFriendsSection{
    display: block !important;
  }
}