.expenseSection_mainWrap {
  /* height: 100vh; */
  padding: 26px;
}
.expensesLogo {
  display: flex;
  align-items: center;
  justify-content: center;
}
.trackExpensesSection {
  display: flex;
  align-items: center;
}

.exp_img img {
  max-width: 100% !important;
  height: 100%;
  object-fit: cover !important;
}

@media screen and (max-width: 768px) {
  .expenseSection_mainWrap {
    height: 100% !important;
    padding: 0 12px !important;
  }
  .expenseSection_mainWrap {
    gap: 30px !important;
  }
}
@media screen and (min-width: 769px) and (max-width: 899px) {
  .expenseSection_mainWrap {
    height: 100% !important;
    gap: 30px !important;
  }
}
@media screen and (min-width: 900px) and (max-width: 1280px) {
  .expenseSection_mainWrap {
    height: 100% !important;
  }
}
