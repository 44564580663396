.securityTitle {
    padding: 1px 103px 1px 103px;
    border-bottom: 1px solid rgb(217, 217, 217);
    color: #666666;
    background-color: #fcfcfc;
    font-size: 25px;
    line-height: 100%;
    font-weight: bold;
}

.securityText{
    padding: 1px 103px 1px 103px;
    background-color: #fcfcfc;
    color: #666666;
    font-size: 25px;
    line-height: 100%;
    font-weight: bold;
    margin-top: 50px;
}

.securityHead {
    margin: 15px 0 15px 0;
}

.securityHeader {
    padding-top: 125px;
}

@media screen and (max-width: 1240px) {
    .securityTitle {
        padding: 1px 26px 1px 26px;
    }
}