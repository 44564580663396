.errorheader {
  display: flex;
  flex-direction: column !important;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding-top: 160px; 
}

.errorImg {
  margin: -30px auto;
  width: 540px;
  height: 300px; 
}

.gobackbtn {
  background: linear-gradient(136deg, #ffd87a 2.61%, #f6b825 96.58%), #edb61e;
  color: black !important;
  font-family: "Montserrat" !important;
  border-radius: 4px;
  font-weight: 500;
  border: none;
  height: 45px;
  font-size: 18px;
  margin-top: 24px;
  cursor: pointer;
}

@media only screen and (max-width: 1326px) {
  .errormain {
    height: 93vh;
  }
}

@media only screen and (max-width: 610px) {
  .errorImg {
    margin: -30px auto;
    width: 480px;
    height: 280px; 
  }
  .errormain {
    height: 95vh;
  }
}

@media only screen and (max-width: 480px) {
  .errorImg {
    margin: -30px auto;
    width: 400px;
    height: 200px; 
  }
  .errormain {
    height: 78vh;
  }
}

@media only screen and (max-width: 400px) {
  .errorImg {
    margin: -30px auto;
    width: 300px;
    height: 180px; 
  }
  .errormain {
    height: 67vh;
  }
}

.errortext{
  display: flex !important;
  justify-content: center !important;
  text-align: center !important;
  align-items: center !important;
  margin-top: 12px !important;
  color: black !important;
  font-family: "Montserrat" !important;
  font-size: 50px !important;
  font-weight: 700 !important;
}

@media only screen and (max-width: 610px) {
  .errortext{
    display: flex !important;
    justify-content: center !important;
    text-align: center !important;
    align-items: center !important;
    margin-top: 34px !important;
    color: black !important;
    font-family: "Montserrat" !important;
    font-size: 30px !important;
    font-weight: 700 !important;
  }
}