.mainNavbar {
  padding: 10px 103px 10px 103px;
  background-color: white;
  width: 100%;
  z-index: 1000;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  box-shadow: 0 10px 8px rgba(0, 0, 0, 0.1);
}
.logoimage {
  width: 85px;
  height: 80px;
  cursor: pointer;
}
.mainPrivacyHeader {
  padding-top: 70px;
}
.pivacyTital {
  color: black;
  font-size: 30px;
  line-height: 100%;
  font-weight: bold;
}
@media screen and (max-width: 600px) {
  .pivacyTital {
    font-size: 19px !important;
  }
}
.pivacyMainText {
  padding: 20px 103px 0px 103px !important;
}
.privacyPolicyHead {
  font-size: 16px !important;
  font-weight: bold !important;
  letter-spacing: 1px !important;
}
.privacyPolicyInformation {
  font-size: 16px !important;
  letter-spacing: 1px !important;
}
.privacyPolicyInformationli {
  display: flex !important;
  font-size: 16px !important;
  letter-spacing: 1px !important;
}
@media screen and (max-width: 460px) {
  .pivacyMainText {
    padding: 38px 26px 0px 26px !important;
  }
}
@media screen and (max-width: 1240px) {
  .mainNavbar {
    padding: 10px 26px 10px 26px;
  }
  .pivacyMainText {
    padding: 20px 26px 0px 26px;
  }
  .pivacyTital {
    padding: 1px 26px 1px 26px;
  }
}
.navebarHead {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
}
