.footercontainer {
  padding-top: 100px !important;
}

.footer_mainwrap {
  background: #000;
  width: 100% !important;
  flex-shrink: 0;
  display: flex !important;
}

.footerlogowrap {
  color: white !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  position: relative;
}
.poweredby {
  color: #fff;

  font-family: "Montserrat" !important;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px; 
  letter-spacing: 0.3px;
}
.poweredby a {
  padding-left:5px;
  text-decoration: none !important;
  color: white !important;
}
.copyrightinformationwrap {
  display: flex;
  justify-content: center;
}
.copyrightinformation {
  color: #fff;

  font-family: "Montserrat" !important;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px; 
  letter-spacing: 0.3px;
}
.poweredbywraper {
  padding-left: 103px;
}

@media screen and (max-width: 768px){
  .footer_mainwrap{
    display: block !important;
  }
  .footerlogowrap{
    display: none !important;
  }
  .poweredbywraper{
		padding-left: 103px;
		padding: 0;
		justify-content: center;
		display: flex;
		margin-bottom: 0 !important;
	}
}
@media screen and (min-width: 769px) and (max-width:899px){
  .footerlogowrap{
    display: none !important;
  } 
  .footer_mainwrap{
    display: block !important;
  }
}
@media screen and (min-width: 900px) and (max-width:1240px){
   .footerlogowrap{
    display: none !important;
  } 
}
