
.headersection_mainsection{
background: #F6F6F6;
}

.headersection_mainwrap {
  height: 100vh;
  padding: 26px;
  
}
.logosection {
  display: flex;
  align-items: center;
  justify-content: center;
}
.loginformsection {
  display: flex;
  align-items: center;
}

@media screen and (max-width: 1020px) {
  .loginformsection {
    padding: 12px;
  }
}

@media screen and (max-width: 400px){
  .headersection_mainwrap{
    padding: 2px 0px 10px 2px !important;
    height: auto !important;
  }
}

@media screen and (max-width: 768px){
  .headersection_mainwrap{
    padding: 10px;
    height: auto !important;
    gap: 30px !important;
  }
}
@media screen and (min-width: 769px) and (max-width:899px){
  .headersection_mainwrap{
    height: auto !important;
    gap: 30px !important;
  }
}
@media screen and (max-width: 899px) {
  .loginformsection {
    padding: 0px;
  }
}
@media screen and (min-width: 900px) and (max-width:1240px){
  .headersection_mainwrap{
    height: auto !important;
    padding: 26px 0 !important ;
  }
}