.containermainwrap{
  padding: 0px 79px 0px 79px !important;
 
}

.mainWrap{
  max-width: 1313px;
  width: 100%;
  margin: 0px auto;
}


.containerwrap {
  margin-top: 24px !important;
}

.flexwrapper{
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.incomeTitleexpenses {
  color: #f5b820;
  font-family: "Montserrat";
  font-size: 16px !important;
  font-style: normal;
  font-weight: 500 !important;
  line-height: normal;
  text-transform: uppercase;
  margin-left: 10px;
}

.circlewithtext {
    width: 75.508px;
    height: 76px;
    flex-shrink: 0;
    border-radius: 50%;
    background-color: #fdd26b;
    margin-right: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .title {
    color: #000;
    font-family: "Montserrat";
    font-size: 36px !important;
    font-style: normal;
    font-weight: 700 !important;
    line-height: 51px; 
  }

  .textheading {
    color: #272521;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 130%; 
    letter-spacing: 0.4px;
    display: block;
  }

  .textdescription {
    color: rgba(30, 30, 30, 0.6);
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; 
  }

  .mt-51{
    margin-top: 51px;
  }



    @media only screen and (max-width: 768px) {
      .containermainwrap{
        padding:30px 0px 0px 0px !important;
      }
      
    }

  @media only screen and (max-width : 600px){
    .title {
      font-size: 20px !important;
      line-height: 36px; 
    }  
  }

  @media screen and (max-width: 440px) {
  .circlewithlogo {
    margin: 0 auto !important;
  }
}