.textfieldwrap.focused {
  border: 1px solid #575656 !important;
}

.footerloginwrap {
  padding-top: 97px;
  padding-left: 103px;
}

.footerdesihisabtitle {
  display: block;
  color: #fff;
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
}

.textfieldwrap {
  width: 293px !important;
  height: 52px !important;
  flex-shrink: 0;
  border-radius: 5px !important;
  border: 0.5px solid #a6a6a6 !important;
}

.textfieldmainwrap {
  margin-top: 21px !important;
  margin-bottom: 10px !important;
  display: flex !important;
  position: relative;
  font-size: 16px;
}

.textfieldmainwrap fieldset {
  border-width: 0px !important;
}
.textfieldmaincaptcha fieldset {
  border-width: 0px !important;
}

.startedbutton {
  width: 130.42px;
  height: 52px;
  color: #000 !important;

  text-align: center;
  font-family: "Montserrat" !important;
  font-size: 16px !important;
  font-style: normal;
  font-weight: 500 !important;
  line-height: normal;
  border-radius: 5px;
  background: linear-gradient(136deg, #ffd87a 2.61%, #f6b825 96.58%), #edb61e;
  box-shadow: 0px 5px 25px 0px rgba(209, 151, 63, 0.2);
  text-transform: capitalize !important;
  margin-left: 16px !important;
}
.InputAdornment {
  color: white !important;
}

.textfieldmainwrap input::placeholder {
  color: white !important;
}

.textfieldmainwrap input {
  color: white !important;
  padding: 14.5px 14px;
  padding-left: 12px !important;
  border-left: 1px solid lightgray !important;
  font-size: 16px;
}

.InputAdornment {
  padding-right: 5px !important;
}
.footerloginimage {
  width: 140px;
  height: 140px;
}

.textfieldmaincaptcha input {
  padding: 15.5px 14px;
  padding-left: 12px !important;
}
.textfieldmaincaptcha {
  margin-top: 5px;
}
.loginSuccessMessage {
  width: 74%;
  bottom: -24px;
  left: 0px;
  font-weight: 500;
}
.networkErrorMessage {
  width: 74%;
  bottom: -24px;
  left: 0px;
  font-weight: 500;
  color: red !important;
}

.loginSuccessMessagemobilefooter {
  margin-top: 10px;
  bottom: -24px;
  left: 0px;
  font-weight: 500;
}
.networkErrorMessagemobilefooter {
  margin-top: 10px;
  bottom: -24px;
  left: 0px;
  font-weight: 500;
  color: red !important;
}

@media screen and (min-width: 600px) and (max-width: 768px) {
  .loginSuccessMessagemobilefooter {
    width: 50%;
    margin-left: 25%;
  }
  .networkErrorMessagemobilefooter {
    width: 50%;
    margin-left: 25%;
  }
}

@media screen and (min-width: 400px) and (max-width: 599px) {
  .loginSuccessMessagemobilefooter {
    width: 80%;
    margin-left: 12%;
  }
  .networkErrorMessagemobilefooter {
    width: 80%;
    margin-left: 12%;
  }
}
@media screen and (max-width: 899px) {
  .loginSuccessMessage {
    margin: 0 auto !important;
    width: 400px;
  }
  .networkErrorMessage {
    margin: 0 auto !important;
    width: 400px;
  }
}
.invalidPhoneNumberMessagemobilefooter {
  margin-top: 10px;
  bottom: -24px;
  left: 0px;
  font-weight: 500;
  color: red;
}
.invalidPhoneNumberMessage {
  margin-top: 10px;
  bottom: -24px;
  left: 0px;
  font-weight: 500;
  color: red;
}

@media screen and (min-width: 769px) and (max-width: 899px) {
  .invalidPhoneNumberMessage {
    padding-right: 175px;
  }
}

.captchaErrorMessage {
  display: flex;
  justify-content: center;
  align-items: center;
  color: red;
  margin: 0px 0 11px 0;
}
.loginCaptchaModal {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}
.captchaBox {
  background-color: white;
  border-radius: 8px;
  padding: 10px;
}
.loadCanvas {
  display: flex;
  justify-content: center;
  align-items: center;
}
.captchaTchatextfiel {
  width: 164.42px !important;
  height: 52px !important;
  flex-shrink: 0;
  border-radius: 5px !important;
  border: 0.5px solid #a6a6a6 !important;
}
.reloadCaptcha {
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .footerloginwrap {
    padding: 26px !important;
    text-align: center;
  }
  .textfieldmainwrap {
    display: block !important;
  }
}

@media screen and (min-width: 769px) {
  .invalidPhoneNumberMessagemobilefooter {
    display: none;
  }
  .loginSuccessMessagemobilefooter {
    display: none;
  }
  .networkErrorMessagemobilefooter {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .invalidPhoneNumberMessage {
    display: none;
  }
  .loginSuccessMessage {
    display: none;
  }
  .networkErrorMessage {
    display: none;
  }
  .startedbutton {
    margin-top: 10px !important;
  }
}
@media screen and (min-width: 769px) and (max-width: 899px) {
  .footerloginwrap {
    padding-left: 0 !important ;
    text-align: center !important;
  }
  .textfieldmainwrap {
    justify-content: center;
  }
}
@media screen and (min-width: 900px) and (max-width: 1240px) {
  .footerloginwrap {
    padding-left: 26px !important;
  }
}
