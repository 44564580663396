.footerlogotext {
  color: white !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  position: absolute !important;
  top: -85px;
}
.mobileimagefooter {
  height: 499px !important;
  width: 279px;
}
@media screen and (min-width: 1241px) and (max-width:1370px){
  .mobileimagefooter{
    max-width: 100% !important;
  }
  .footerlogotext{
    top: -85px !important;
  }
}