.footerlinkmainwrap {
  padding-top: 86px !important;
  padding-left: 38px !important;
}

.quicklink_mainTitle {
  color: #fff;
  font-size: 20px;
  font-weight: 700;
  font-style: normal;
  text-transform: capitalize;
  line-height: 60px; 
}
.googleplay {
  display: block !important;
  line-height: 23px !important;
}



.support_mainTitle {
  color: #fff;
  font-size: 20px;
  font-weight: 700;
  font-style: normal;
  line-height: 60px; 
  text-transform: capitalize;
}

.socialmedia_mainTitle {
  color: #fff;
  font-size: 20px;
  font-weight: 700;
  font-style: normal;
  line-height: 60px; 
  text-transform: capitalize;
}

.quickullistmain {
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  color: white !important;
  text-transform: capitalize;
  padding-left: 4px !important;
  list-style-type: none !important;
  line-height: 32px; 
}

.supportullistmain {
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  color: white !important;
  text-transform: capitalize;
  padding-left: 4px !important;
  list-style-type: none !important;
  line-height: 32px; 
}

.socialmedialistwrap {
  font-size: 14px;
  color: white !important;
  list-style-type: none !important;
  padding-left: 4px !important;
  font-style: normal;
  font-weight: 400;
  line-height: 32px; 
  text-transform: capitalize;
}

.googleplaybtn {
  width: 200px;
  background-color: black !important;
  font-family: "Montserrat" !important;
  color: white !important;
  text-transform: none !important;
  border: 1px solid white !important;
}

@media screen and (min-width: 769px) and (max-width: 899px) {
  .gridwrapbuttoncontainer {
    max-width: 65% !important;
  }
}

.applebtn {
  width: 200px;
  background-color: #000 !important;
  font-family: "Montserrat" !important;
  color: white !important;
  border: 1px solid white !important;
  text-transform: none !important;
}
.gridwrapbuttoncontainer {
  padding-left: 46px !important;
  justify-content: space-around;
  margin-top: 18px;
}
.footerlinkcolorlogo {
  text-decoration: none !important;
  color: white !important;
  margin-left: 8px !important;
}
.footerlinkcolor {
  text-decoration: none !important;
  color: white !important;
}

.listcontainer {
  display: flex !important;
  align-items: center;
}
.image {
  width: 16px;
  height: 16px;
}

.button_icon {
  border-radius: 0 !important;
  width: auto !important ;
}

@media screen and (max-width: 768px) {
  .gridwrapbutton {
    padding: 0 !important;
  }
  .gridwrapbuttoncontainer {
    padding: 0 46px !important;
  }
  .gridwrapbutton {
    justify-content: center !important;
  }
  .footerlinkmainwrap {
    padding-top: 26px !important;
  }
}
@media screen and (max-width: 600px) {
  .googleplaybtn {
    width: 90% !important;
  }
  .applebtn {
    width: 90% !important;
  }
}
@media screen and (max-width: 599px) {
  .googleplaybtn {
    width: 100% !important;
  }
  .applebtn {
    width: 100% !important;
  }
}
@media screen and (max-width: 407px) {
  .googleplaybtn {
    width: 100% !important;
  }
  .applebtn {
    width: 100% !important;
    margin-top: 15px !important;
  }
  .gridwrapbuttoncontainer {
    flex-direction: column !important;
  }
}

@media screen and (min-width: 900px) and (max-width: 1240px) {
  .gridwrapbuttoncontainer {
    padding-left: 16px !important;
    padding-right: 16px !important;
  }
  .footerlinkmainwrap {
    padding-right: 16px !important;
    padding-left: 16px !important;
  }
}

.contactUs {
  cursor: pointer;
  width: 40%;
}
