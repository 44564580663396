.ReportsSection_mainwrap {
  padding: 26px;
}
.logosection {
  display: flex;
  align-items: center;
  justify-content: center;
}
.loginformsection {
  display: flex;
  align-items: center;
}
.ReportsSection{
  align-items: center !important;
}
.ReportsSection{
  display: flex;
  align-items: center;
}
@media screen and (max-width: 768px){
  .ReportsSection_mainwrap{
    padding: 10px;
    gap: 30px;
    height: auto  !important;
  }
}
@media screen and (min-width: 769px) and (max-width:899px){
  .ReportsSection_mainwrap{
    gap: 30px !important;
    height: auto !important;
  }
}
@media screen and (min-width: 900px) and (max-width:1280px){
  .ReportsSection_mainwrap{
    height: auto !important;
  }
}