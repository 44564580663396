.slide{
  cursor: pointer;
}
.testimonialsmainboxwrap {
  width: 100%;
  height: 283px;
  flex-shrink: 0;
  border-radius: 30px;
  background: #f6b825;
}
.ShareableExpensSectionTrack {
  position: relative;
  padding-left: 127px;
  display: flex;
  justify-content: center;
  
}
.shareableimage {
  position: absolute;
  top: -75px;
  height: 358px;
}

.ShareableExpensSection {
  padding: 56px;
}
@media only screen and (max-width : 600px){
  .ShareableExpensSection {
    padding: 20px;
  }
}

.lineImage {
  margin-bottom: 3px;
}

.incomeTitleexpenses {
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
  margin-left: 10px !important;
}

.saidClients {
  color: #000;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 51px; 
  width: 480px;
  display: block;
}
@media only screen and (max-width : 600px){
  .saidClients {
    font-size: 21px;
  }
}
.contenttext {
  color: #000;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; 
  width: 491px;
}
.authorname {
  display: block !important;
  color: #000;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%; 
  letter-spacing: 0.4px;
}
.buttonwrap {
  display: flex;
  justify-content:flex-end;
}
.currentContent {
  padding: 10px !important;
}
.prevButton {
  width: 40px;
  height: 40px;
  background-color: #fff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.nextButton {
  width: 40px;
  height: 40px;
  flex-shrink: 0;
  border-radius: 50%;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.ratingcontent label{
color: white !important;
}
.activeButton {
  background-color: #000;
}
.btnprevarrow{
  width: 16px;
}
@media screen and (max-width: 768px){
  .testimonialsmainboxwrap{
    height: auto !important;
    width: auto !important;
  }
  .testimonialsmainsection{
    width: auto !important;
    display: block !important;
  } 
  .ShareableExpensSectionTrack {
    display: none !important;
  }
  .saidClients{
    width: auto !important;
  }
  .buttonwrap{
    justify-content: center !important;
  }
}

@media screen and (min-width: 769px) and (max-width:899px){
  .ShareableExpensSectionTrack {
    display: none !important;
  }
  .testimonialsmainsection{
    width: auto !important;
    display: block !important;
  }
  .testimonialsmainboxwrap{
    height: auto !important;
    width: auto !important;
  } 
  .buttonwrap{
    justify-content: center !important;
  }
}
@media screen and (min-width: 900px) and (max-width:1240px){
  .ShareableExpensSection{
    padding: 28px !important;
  }
}
