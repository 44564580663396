.welcomemainwrap {
  padding-top: 60px;
}

.whyusmainwrap {
  margin-top: 100px;
  background-color: #F6F6F6;
  padding: 100px 0px;
}

.welcomemainwrap h1,
.whyusmainwrap h1,
.welcometitle {
  text-align: center;
  font-weight: 700;
  font-size: 36px;
  line-height: 51px;
  margin-bottom: 15px;
}

.whyusmainwrap h1 {
  margin-bottom: 100px;
}

.welcometext {
  text-align: center;
  font-size: 17px;
  line-height: 28px;
  color: #1e1e1e99;
  font-family: Montserrat !important;
  margin-bottom: 50px !important;
}

.whyuscol {
  text-align: center;
}

.whyuscol img {
  margin-bottom: 30px;
}

.textheading {
  color: #000;
}

.title{
  font-weight: 700;
  color: #000;
  font-size: 36px;
  line-height: 51px;
  display: block;
}

.getstartedtext{
    line-height: 24px;
    font-size: 15px;
    color: #000;
    margin-bottom: 20px;
    display: block;

}

.divider {
  width: 27px;
  height: 1px;
  background: #FDD26B;
  margin: 14px auto;
  display: block;
}

.textdescription {
  padding: 0px 28px;
}

.getstartedwrap {
  background: #f6b825;
  border-radius: 30px;
  flex-shrink: 0;
  height: 400px;
  width: 100%;
  margin-top: 130px;
}

.getstartedimgwrap {
  position: relative;
  height: 400px;
  text-align: center;
}

.getstartedimg {
  height: 598px;
  position: absolute;
  top: -199px;
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  left: 50%;
  margin-left: -169px;
 

}

.googleplaybtn {
  width: 200px;
  background-color: black !important;
  color: white !important;
  text-transform: none !important;
  border-radius: 10px !important;
  padding: 7px 14px !important;
  margin-right: 20px !important;
}

.applebtn {
  width: 200px;
  background-color: #000 !important;
  color: white !important;
  text-transform: none !important;
  border-radius: 10px !important;
  padding: 7px 14px !important;
}

.gridwrapbutton {
  display: flex;
  justify-content: center;
}
.button_icon {
  border-radius: 0 !important;
  width: auto !important ;
}

.btnwrap {
  display: flex;
  flex-direction: column;
  line-height: 24px;
  text-wrap: nowrap !important;
}

.incomeTitleexpenses {
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
  margin-left: 10px !important;
}


.Getstartedrightcol{
  padding-right: 200px;
}

.imgright{
  text-align: right;
}

.imgright img{
  height: 100%;
    max-width: 100% !important;
    object-fit: cover !important;
}

.howtouseappmainwrap{
  padding: 50px 79px 0px 79px !important;
  margin-bottom: 100px;

}

.howtouseappbgcolor{
background-color: #F6F6F6;
padding: 20px 0px 50px 0px;
margin: 100px 0px 80px 0px;
}


@media screen and (min-device-width: 1200px) and (max-device-width: 1500px) { 
  .Getstartedrightcol{
    padding-right: 80px;
  }
}

@media only screen and (max-width: 1200px) {
  .getstartedwrap{height: auto; padding: 30px; margin-top: 0;}
  .getstartedimgwrap {height: auto;}
  .getstartedimg {display: none;}
  .Getstartedrightcol{
    padding-right: 0px;
  }
}


@media only screen and (max-width: 768px) {
  .whyuscol{
    margin-bottom: 20px;
  }
  .imgright{
    margin-top: 20px;
  }
  .howtouseappmainwrap{
    padding: 10px !important;
  }
  
}

@media only screen and (max-width: 511px) {
  .googleplaybtn{
    margin-bottom: 20px;
  }
}