.ShareableExpensSection_mainsection{
  background: #F6F6F6;
}
.ShareableExpensSection_mainWrap {
  padding: 26px;
  margin-top: 40px !important;
}
.ShareableExpensSection {
  display: flex;
  align-items: center;
  justify-content: center;
}
.ShareableExpensSectionTrack {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 768px) {    
  .ShareableExpensSection_mainWrap{
    height: auto !important;
    padding: 10px;
    gap: 30px;
  }
  .ShareableExpensSection{
    display: block !important;
    padding: 26px  0 !important;
  }
}
@media screen and (min-width: 769px) and (max-width:899px){
  .ShareableExpensSection_mainWrap{
    height: auto !important;
    gap: 30px !important;
  }
  .ShareableExpensSection{
    padding: 0 !important;
  }
}
@media screen and (min-width: 900px) and (max-width:1240px){
  .ShareableExpensSection_mainWrap{
    height: auto !important;
  }
  .ShareableExpensSection{
    display: block !important;
  }
}