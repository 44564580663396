.freeapptitle {
  width: 410px;
  border-radius: 400px;
  background: #f6b825;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
}

@media only screen and (max-width: 899px) {
  .freeapptitle {
    margin: 0 auto;
  }
}

@media only screen and (max-width: 480px) {
  .freeapptitle {
    width: 100%;
  }
}

.title {
  color: #000;
  text-align: center;
  font-size: 35px;
  font-style: normal;
  font-weight: 500;
  margin: 0;
}

@media only screen and (max-width: 420px) {
  .title {
    font-size: 20px !important;
  }
}

@media only screen and (max-width: 600px) {
  .title {
    font-size: 27px;
  }
}

.maintextwrap {
  margin-top: 32px;
}

.textwrap {
  color: #000;
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: 40px;
}

.textfieldwrap.focused {
  border: 1px solid #575656 !important;
}

.textfieldwrap {
  width: 293px !important;
  height: 52px !important;
  flex-shrink: 0;
  border-radius: 5px !important;
  border: 0.5px solid #a6a6a6 !important;
}

.captchaTchatextfiel {
  width: 164.42px !important;
  height: 52px !important;
  flex-shrink: 0;
  border-radius: 5px !important;
  border: 0.5px solid #a6a6a6 !important;
}

.loadCanvas {
  display: flex;
  justify-content: center;
  align-items: center;
}

.captchaErrorMessage {
  display: flex;
  justify-content: center;
  align-items: center;
  color: red;
  margin: 0px 0 11px 0;
}

.textfieldmainwrap {
  margin-top: 21px !important;
  position: relative;
  display: flex;
}

.loginSuccessMessage {
  width: 64%;
  margin-left: 4px;
  margin-top: 16px;
  bottom: -44px;
  left: 11px;
  font-weight: 500;
}
.networkErrorMessage {
  width: 64%;
  margin-left: 4px;
  margin-top: 16px;
  bottom: -44px;
  left: 11px;
  font-weight: 500;
  color: red !important;
}

@media screen and (min-width: 769px) and (max-width: 899px) {
  .loginSuccessMessage {
    width: 50%;
  }
  .networkErrorMessage {
    width: 50%;
  }
}

@media screen and (min-width: 900px) and (max-width: 1024px) {
  .loginSuccessMessage {
    width: 70%;
  }
  .networkErrorMessage {
    width: 70%;
  }
}

.loginSuccessMessagemobile {
  width: 100%;
  margin-top: 16px;
  bottom: -44px;
  left: 11px;
  font-weight: 500;
}
.networkErrorMessagemobile {
  width: 100%;
  margin-top: 16px;
  bottom: -44px;
  left: 11px;
  font-weight: 500;
  color: red !important;
}

@media screen and (max-width: 768px) {
  .loginSuccessMessagemobile {
    margin-left: 0%;
    width: 300px;
    margin: 7px auto !important;
    text-align: center;
  }
  .networkErrorMessagemobile {
    margin-left: 0%;
    width: 300px;
    margin: 7px auto !important;
    text-align: center;
  }
}
@media screen and (max-width: 348px) {
  .loginSuccessMessagemobile {
    width: 260px;
  }
  .networkErrorMessagemobile {
    width: 260px;
  }
}

.invalidPhoneNumberMessagemobile {
  text-align: center;
  margin-top: 10px;
  bottom: -24px;
  left: 11px;
  font-weight: 500;
  color: red;
}

.invalidPhoneNumberMessage {
  margin-left: 6px;
  margin-top: 5px;
  bottom: -24px;
  left: 11px;
  font-weight: 500;
  color: red;
}

@media screen and (max-width: 768px) {
  .invalidPhoneNumberMessage {
    display: none;
  }

  .loginSuccessMessage {
    display: none;
  }
  .networkErrorMessage {
    display: none;
  }
}

@media screen and (min-width: 769px) {
  .invalidPhoneNumberMessagemobile {
    display: none;
  }

  .loginSuccessMessagemobile {
    display: none;
  }
  .networkErrorMessagemobile {
    display: none;
  }
}

.textfieldmainwrap fieldset {
  border-width: 0px !important;
}

.captchaTextfieldmain fieldset {
  border-width: 0px !important;
}

.startedbutton {
  width: 130.42px;
  height: 52px;
  color: #000 !important;
  text-transform: capitalize !important;
  text-align: center;
  font-family: "Montserrat" !important;
  font-size: 16px !important;
  font-style: normal;
  font-weight: 500 !important;
  line-height: normal;
  border-radius: 5px;
  background: linear-gradient(136deg, #ffd87a 2.61%, #f6b825 96.58%), #edb61e;
  box-shadow: 0px 5px 25px 0px rgba(209, 151, 63, 0.2);
  margin-left: 16px !important;
}

.loaderLogin {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

.googleplaybtn {
  width: 200px;
  background-color: black !important;
  color: white !important;
  text-transform: none !important;
  border-radius: 10px !important;
  padding: 7px 14px !important;
  margin-right: 20px !important;
}

.applebtn {
  width: 200px;
  background-color: #000 !important;
  color: white !important;
  text-transform: none !important;
  border-radius: 10px !important;
  padding: 7px 14px !important;
}

@media screen and (max-width: 768px) {
  .gridwrapbuttoncontainer {
    margin-top: 20px !important;
  }
}

.gridwrapbuttoncontainer {
  margin-top: 70px;
}


@media screen and (min-width: 769px) and (max-width: 899px) {
  .gridwrapbuttoncontainer {
    max-width: 62% !important;
  }
}

@media screen and (max-width: 768px) {
  .gridwrapbuttoncontainer {
    display: flex;
    justify-content: center;
    text-align: center;
  }
}

.firstbutton {
  padding-left: 0px !important;
}

.getitgooleplay {
  text-decoration: none !important;
  color: white;
  text-wrap: nowrap !important;
}

.textfieldmainwrap input {
  padding: 14.5px 14px;
  border-left: 1px solid lightgray !important;
  padding-left: 12px !important;
  font-size: 16px;
}

.InputAdornment {
  padding-right: 5px !important;
}

.captchaTextfieldmain input {
  padding: 15.5px 14px;
  padding-left: 12px !important;
}

.button_icon {
  border-radius: 0 !important;
  width: auto !important;
}

.loginCaptchaModal {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

.captchaBox {
  background-color: white;
  border-radius: 8px;
  padding: 10px;
}

.reloadCaptcha {
  cursor: pointer;
}

.btnwrap {
  display: flex;
  flex-direction: column;
  line-height: 24px;
  text-wrap: nowrap !important;
}

@media screen and (max-width: 414px) {
  .btnwrap {
    text-align: start !important;
    width: 130px !important;
  }
}
@media screen and (max-width: 375px) {
  .btnwrap {
    text-align: start !important;
    width: 123px !important;
  }
  .googleplaybtn {
    margin-right: 15px !important;
  }
  .getitgooleplay {
    text-wrap: nowrap !important;
    margin-left: 3px;
  }
}

@media screen and (max-width: 320px) {
  .btnwrap {
    width: 100% !important;
  }
}

@media screen and (max-width: 420px) {
  .textwrap {
    font-size: 16px !important;
    line-height: normal;
  }
}

@media screen and (max-width: 768px) {
  .maintextwrap {
    width: auto;
    margin-top: 32px;
  }

  .textwrap {
    font-size: 24px;
    line-height: normal;
  }

  .textfieldmainwrap {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  .captchaTextfieldmain {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-top: 5px;
  }

  .startedbutton {
    display: block !important;
    margin: 20px auto !important;
  }

  .textfieldwrap {
    margin: 0px auto !important;
  }

  .captchaTchatextfiel {
    margin: 0px auto !important;
  }
}
@media screen and (min-width: 769px) {
  .btngrid {
    display: flex;
    justify-content: center;
    text-align: center;
  }
}
@media screen and (max-width: 768px) {
  .btngrid {
    display: flex;
    justify-content: center;
    text-align: center;
  }

  .gridwrapbutton {
    display: flex;
    justify-content: center;
  }
}

@media screen and (max-width: 599px) {
  .space {
    display: none;
  }

  .gridwrapbuttoncontainer {
    gap: 12px !important;
  }

  .gridwrapbutton {
    padding: 0 !important;
    text-align: center;
  }

  .btngrid {
    text-align: center;
  }
}

@media screen and (max-width: 480px) {
  .textfieldmainwrap {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  .captchaTextfieldmain {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  .startedbutton {
    display: block !important;
    width: 100% !important;
    margin: 0px auto !important;
  }

  .textfieldwrap {
    margin: 0px auto !important;
    width: 100% !important;
  }

  .captchaTchatextfiel {
    margin: 0px auto !important;
    width: 100% !important;
  }

  .googleplaybtn {
    width: 100% !important;
  }

  .applebtn {
    width: 100% !important;
  }

  .gridwrapbuttoncontainer {
    margin-top: 10px !important;
  }
}

@media screen and (min-width: 900px) and (max-width: 1240px) {
  .textfieldmainwrap {
    display: flex !important;
  }

  .captchaTextfieldmain {
    display: flex !important;
  }

  .startedbutton {
    margin-left: 5px !important;
  }
}
