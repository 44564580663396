.btncancel {
  color: black !important;
  border: 1px solid grey !important;
  font-family: "Montserrat" !important;
}

.maindialogcontent {
  overflow-y: auto;
  width: 99.75%;
}

.maindialogcontent::-webkit-scrollbar {
  width: 5px;
}

.maindialogcontent::-webkit-scrollbar-thumb {
  background-color: #888; /* Color of the scrollbar thumb */
  border-radius: 6px; /* Radius of the scrollbar thumb */
}

.maindialogcontent::-webkit-scrollbar-track {
  background-color: #eee; /* Color of the scrollbar track */
}

.btnsend {
  width: 130.42px;
  height: 52px;
  color: #000 !important;
  text-align: center;
  font-family: "Montserrat" !important;
  font-size: 16px !important;
  font-style: normal;
  font-weight: 500 !important;
  line-height: normal;
  border-radius: 5px;
  background: linear-gradient(136deg, #ffd87a 2.61%, #f6b825 96.58%), #edb61e;
  box-shadow: 0px 5px 25px 0px rgba(209, 151, 63, 0.2);
  text-transform: capitalize !important;
  margin-left: 16px !important;
}
.border-color-text {
  border-color: black;
}
.errorMessage {
  color: red;
}
.btnjustify {
  justify-content: center !important;
}
.btnborder {
  border-top: 0.5px solid #d1cece;
}
.closeicon {
  border-bottom: 0.5px solid #d1cece;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.justifycloseicon {
  cursor: pointer;
  margin-right: 10px;
}
.dialogContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 !important;
}
.flexContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.formContainer {
  width: 100%;
  box-sizing: border-box;
}
.forminputmain {
  padding: 0 20px;
}

@media (max-width: 600px) {
  .flexContainer {
    flex-direction: column;
  }
  .formContainer,
  .iframeContainer {
    width: 100%;
  }
}
.reloadCaptcha {
  border-left: 1px solid rgb(192, 187, 187);
  padding-left: 5px;
}
.loadCanvas {
  border-radius: 4px;
  margin-top: 8px;
  height: 56px;
  width: 49%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid rgb(192, 187, 187);
  padding: 7.5px 14px;
}
@media only screen and (max-width: 767px) {
  .loadCanvas {
    width: 100%;
  }
}

.captchaTchatextfiel {
  width: 164.42px !important;
  height: 52px !important;
  flex-shrink: 0;
  border-radius: 5px !important;
  border: 0.5px solid #a6a6a6 !important;
}

.contactfiels {
  display: flex !important;
  justify-content: space-between !important;
}
.namefiels {
  width: 49% !important;
}
.mobilefiels {
  width: 49%;
}
@media screen and (max-width: 425px) {
  .contactfiels {
    flex-direction: column !important;
  }
  .namefiels {
    width: 100% !important;
  }
  .mobilefiels {
    width: 100%;
  }
}

.loadCaptcha {
  display: flex;
  margin: 0 auto;
  justify-content: center;
  text-align: center;
}
.captchaBox {
  display: flex;
  justify-content: space-between;
}
@media only screen and (max-width: 767px) {
  .captchaBox {
    display: block;
  }
}
.captchaErrorMessage {
  color: red;
}
.textfiledwidth {
  width: 49% !important;
}
@media only screen and (max-width: 767px) {
  .textfiledwidth {
    width: 100% !important;
  }
}
@media only screen and (max-width: 767px) {
  .captchaErrorMessage {
    padding-left: 10px;
  }
}

.contactnetworkErrorMessage{
  margin-left: 24px;
  width: 90%;
  font-weight: 500;
  color: red !important;
}