.testimonialsmainwrapcontainer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 26px;
}

@media screen and (max-width: 480px){
  .testimonialsmainwrapcontainer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0px 8px 0px;
  }
}

@media screen and (max-width: 768px){
  .testimonialsmainwrapcontainer {
    height: auto !important;
  }  
}
@media screen and (min-width: 900px) and (max-width:1240px){
  .testimonialsmainwrapcontainer {
    margin-top: 10% !important;
  }
}

@media screen and (min-width: 1241px) and (max-width:1370px){
  .testimonialsmainwrapcontainer {
    margin-top: 5% !important;
  }
}