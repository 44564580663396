.FastSecureSection_mainWrap {
  height: auto;
  padding: 26px;
}
.FastSecureSectionLogo {
  display: flex;
  align-items: center;
  justify-content: center;
}
.FastSecureSection {
  display: flex;
  align-items: center;
  justify-content: center;
}
@media screen and (max-width: 768px){
  .FastSecureSection_mainWrap {
    height: auto !important;
    padding: 10px !important;
    gap: 30px !important;
  }
  .FastSecureSection{
    display: block !important;
  }
}
@media screen and (min-width: 769px) and (max-width:899px){
  .FastSecureSection_mainWrap {
    height: auto !important;
    gap: 30px !important;
  }
}
@media screen and (min-width: 900px) and (max-width:1240px){
  .FastSecureSection_mainWrap{
    height: auto !important;
  }

}