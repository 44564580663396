.faqsTitle {
  padding: 1px 103px 1px 103px;
  border-bottom: 1px solid rgb(217, 217, 217);
  color: #666666;
  font-size: 25px;
  line-height: 100%;
  font-weight: bold;
}
.faqsMain {
  padding: 20px 103px 0px 103px !important;
}
.mainfaqstitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
}
.faqsText {
  cursor: pointer;
  font-size: 16px !important;
  letter-spacing: 1px !important;
  background: linear-gradient(136deg, #ffd87a 2.61%, #f6b825 96.58%), #edb61e !important;
  margin: 10px 0 10px 0;
  padding: 15px 15px 12px 15px;
  border-radius: 10px;
  user-select: none;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.faqsmainbox {
  cursor: pointer;
  font-size: 16px !important;
  letter-spacing: 1px !important;
  background-color: white;
  margin: 10px 0 10px 0;
  padding: 15px 15px 12px 15px;
  border-radius: 10px;
  user-select: none;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.privacyPolicyHead {
  font-weight: bold !important;
  margin: 0 !important;
}

.faqsHead {
  margin: 15px 0 15px 0;
}

.faqsHeader {
  padding-top: 70px;
}
.faqsInformation {
  font-size: 16px !important;
  letter-spacing: 1px !important;
  margin-top: 15px;
  font-weight: 600;
}
@media screen and (max-width: 1240px) {
  .faqsTitle {
    padding: 1px 26px 1px 26px;
  }
  .faqsMain {
    padding: 20px 26px 0px 26px !important;
  }
}

.faqsloader {
  width: 350px;
  height: 350px;
}

.faqsloader {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  height: 50vh;
}

@media screen and (max-width: 425px) {
  .faqsloader {
    width: 300px;
    height: 300px;
  }
}

@media screen and (max-width: 320px) {
  .faqsloader {
    width: 200px;
    height: 200px;
  }
}